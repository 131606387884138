/**
   * EE Helpers
   * Limited utility classes for EE presentation
   *
   * This should be greatly limited to avoid junking up our CSS output.
   */
.ee-help-text-highlight {
  background: yellow;
  color: theme('colors.black');
}
