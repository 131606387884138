@media (max-width: 767px) {
  .autocomplete-wrapper {
    width: 100%;
  }
}

.autocomplete-wrapper > div {
  width: 100%;
}

.autocomplete-wrapper .label {
  font-size: 14px;
  width: 100%;
  color: black;
  padding-right: 40px; /* Adjust to make space for the icon */
}

.autocomplete-wrapper .dropdown {
  width: 100%; /* Make dropdown width match the parent */
  padding-top: 50px;
  padding: 0;
  text-align: left;
  max-height: 280px;
  overflow: hidden;
  overflow-y: auto;
  color: black;
  border-radius: 8px;
  border-top: none;
  position: absolute;
  background-color: #ffffff;
}

/* Modern scrollbar styles */
.autocomplete-wrapper .dropdown::-webkit-scrollbar {
  width: 8px;
}

.autocomplete-wrapper .dropdown::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.autocomplete-wrapper .dropdown::-webkit-scrollbar-thumb {
  background: #7a7979;
  border-radius: 10px;
}

.autocomplete-wrapper .dropdown::-webkit-scrollbar-thumb:hover {
  background: #7a7979;
}

.autocomplete-wrapper .item {
  display: block;
  cursor: pointer;
  font-size: 14px;
  padding: 15px;
}

.autocomplete-wrapper .item.selected-item {
  background-color: #f7f7f7;
  color: #2d2926;
}

.autocomplete-wrapper .item:hover {
  background-color: #f7f7f7;
  color: #2d2926;
}

.loading {
  font-size: 14px;
  display: flex;
  justify-content: right;
  align-items: right;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  color: black;
  margin-top: 14px;
}

.disabled-field {
  color: theme(colors.gray.medium);
}
