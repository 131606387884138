atomic-search-box::part(wrapper) {
  @apply ring-1 ring-gray;

  border-color: theme('colors.gray.DEFAULT');
}

atomic-search-box::part(wrapper):hover,
atomic-search-box::part(wrapper):focus-within {
  @apply ring-blue ring-2;
}

atomic-search-box::part(clear-button) {
  color: theme('colors.gray.medium');
}

atomic-search-box::part(submit-button) {
  color: theme('colors.white');
}

atomic-search-box::part(submit-button):hover {
  background: theme('colors.blue.navy');
}

atomic-search-box::part(input)::placeholder {
  color: theme('colors.gray.dark');
}

/* :disabled doesn't work with shadow-dom.  Reported as a chromium bug: https://bugs.chromium.org/p/chromium/issues/detail?id=953648 
   Work in Firefox, but that's not good enough.  We set the disabled color through atomic variables though so that works */

/* atomic-search-box::part(submit-button):disabled {
  background: theme('colors.blue.DEFAULT');
} */
