atomic-results-per-page::part(label) {
  color: #595959;
}

atomic-results-per-page::part(button active-button) {
  background: theme('colors.blue.navy') !important;
  border-color: theme('colors.blue.navy') !important;
  border-radius: 100%;
  color: #ffffff !important;
  transition: all 0.2s ease-in-out;
}

atomic-results-per-page::part(button active-button):hover {
  background: theme('colors.blue.DEFAULT') !important;
  border-color: theme('colors.blue.DEFAULT') !important;
  border-radius: 100%;
  color: #ffffff !important;;
}

atomic-results-per-page::part(button) {
  align-items: center;
  border-color: transparent;
  border-radius: 100%;
  border-width: 1px;
  color: theme('colors.gray.darkest');
  display: flex;
  justify-content: center;
  transition: all 0.2s ease-in-out;
}

atomic-results-per-page::part(button):hover {
  color: theme('colors.gray.darkest');
  border-color: theme('colors.blue.navy');
  cursor: pointer;
}
