@layer utilities {
  /**
   * Headings
   *
   * We are duplicating the @apply properties for heading and heading-extendable
   * This allows us to add !important to just the utility versions of these classes
   * while allowing us to extend them into component-tier CSS without the important (see _rte.css)
   *
   * So, if you change a headings @apply properties, you also need to change the heading-extends properties to match
   */

  /* Not sure if we need Super */

  /* .heading-super-extendable {
        @apply font-semibold text-xl;
    }

    .heading-super {
        @apply font-semibold text-xl !important;
    } */

  /* XL */
  .heading-xl-extendable {
    @apply leading-12 text-3xl sm:text-5xl sm:leading-20;
  }

  .heading-xl {
    @apply leading-12 text-3xl sm:text-5xl sm:leading-20 !important;
  }

  /* Large */
  .heading-lg-extendable {
    @apply text-2xl leading-10 sm:text-4xl sm:leading-16;
  }

  .heading-lg {
    @apply text-2xl leading-10 sm:text-4xl sm:leading-16 !important;
  }

  /* Medium */
  .heading-md-extendable {
    @apply text-xl mb-3 leading-6 sm:text-2xl sm:leading-10;
  }

  .heading-md {
    @apply text-xl mb-3 leading-6 sm:text-2xl sm:leading-10 !important;
  }

  /* Small */
  .heading-sm-extendable {
    @apply text-lg leading-4 sm:text-xl sm:leading-8;
  }

  .heading-sm {
    @apply text-lg leading-4 sm:text-xl sm:leading-8 !important;
  }

  /* Extra all */
  .heading-xs-extendable {
    @apply text-base leading-1 sm:text-lg sm:leading-4;
  }

  .heading-xs {
    @apply text-base leading-1 sm:text-lg sm:leading-4 !important;
  }

  /* 2xs */
  .heading-2xs-extendable {
    @apply text-sm leading-1 sm:text-base;
  }

  .heading-2xs {
    @apply text-sm leading-1 sm:text-base !important;
  }

  .rte-body-extendable {
    @apply text-base leading-4 sm:text-base sm:leading-5;
  }

  .rte-body-text-lg {
    @apply text-md leading-4 sm:text-lg sm:leading-4;
  }

  .rte-body-text-default {
    @apply text-base leading-4;
  }

  .rte-body-text-sm {
    @apply text-sm leading-2;
  }
  
  .rte-body-text-xs {
    @apply text-xs leading-2;
  }

  .rte-quote-text {
    @apply font-serif text-md leading-4 sm:text-lg;
  }
}
