* {
  --atomic-primary: theme('colors.blue.DEFAULT');
  --atomic-disabled: theme('colors.blue.DEFAULT');
  --atomic-background: theme('colors.white');
  --atomic-neutral: theme('colors.blue.DEFAULT');
  --atomic-border-radius: 0.25rem;
  --atomic-border-radius-md: 0.5rem;
  --atomic-border-radius-lg: 0.75rem;
  --atomic-border-radius-xl: 1rem;
}
