/* stylelint-disable font-family-no-missing-generic-family-keyword */
/* stylelint-disable order/properties-alphabetical-order */
/* stylelint-disable custom-property-empty-line-before */
/* stylelint-disable declaration-empty-line-before */
/* stylelint-disable rule-empty-line-before */
/* stylelint-disable selector-class-pattern */
/* stylelint-disable font-family-name-quotes */
/* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */

@keyframes qsi-no-button-animation {
  0% {
    --tw-ring-color: var(--btn-border-secondary);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #000000);
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width)
      var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width))
      var(--tw-ring-color);
    color: var(--btn-text-secondary);
  }
  100% {
    color: var(--btn-text-secondary-hover);
    background-color: var(--btn-bg-secondary-hover);
    --tw-ring-color: var(--btn-border-secondary-hover);
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width)
      var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width))
      var(--tw-ring-color);
  }
}

@keyframes qsi-yes-button-animation {
  0% {
    --tw-ring-color: var(--btn-border-primary);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #000000);
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width)
      var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width))
      var(--tw-ring-color);
    color: var(--btn-text-primary);
  }
  100% {
    color: var(--btn-text-primary);
    background-color: var(--btn-bg-primary-hover);
    --tw-ring-color: var(--btn-border-primary-hover);
  }
}


.QSIWebResponsive .QSIWebResponsive-creative-container-fade [class^='QSIWebResponsiveDialog'][class*='_content-spacious'] {
  color: #2d2926 !important;
  font-family: 'Roboto' !important;
  font-style: normal !important;
  padding: 20px 20px 40px !important;
  margin: 0 !important;
}

.QSIWebResponsive .QSIWebResponsive-creative-container-fade [class^='QSIWebResponsiveDialog'][class*='_close-btn'] img {
  height: 22.5px !important;
  width: 22.5px !important;
}

.QSIWebResponsive .QSIWebResponsive-creative-container-fade [class^='QSIWebResponsiveDialog'][class*='_logo'] {
  height: 144.43px !important;
  max-height: 145px !important;
  width: 163.45px !important;
  margin: auto;
}

.QSIWebResponsive .QSIWebResponsive-creative-container-fade [class^='QSIWebResponsiveDialog'][class*='_headline'] {
  color: #2d2926 !important;
  font-family: 'Roboto' !important;
  font-size: 30px !important;
  font-style: normal !important;
  font-weight: 300 !important;
  letter-spacing: -0.01em;
  line-height: 125% !important;
}

.QSIWebResponsive .QSIWebResponsive-creative-container-fade [class^='QSIWebResponsiveDialog'][class*='_description'] {
  color: #2d2926 !important;
  font-family: 'Roboto' !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important;
}

.QSIWebResponsive .QSIWebResponsive-creative-container-fade [class^='QSIWebResponsiveDialog'][class*='_button-container'] button {
  font-size: 16px !important;
  line-height: 16px !important;
  margin-left: 1rem !important;
  margin-right: 1rem !important;
  padding: 12px !important;
}

.QSIWebResponsive .QSIWebResponsive-creative-container-fade [class^='QSIWebResponsiveDialog'][class*='_button-container'] :first-child:hover {
  animation-name: qsi-no-button-animation;
  animation-duration:  .2s;
  animation-timing-function: cubic-bezier(.4,0,.2,1);
  animation-direction: normal;
  animation-fill-mode: forwards;
}

.QSIWebResponsive .QSIWebResponsive-creative-container-fade [class^='QSIWebResponsiveDialog'][class*='_button-container'] :last-child:hover {
  animation: qsi-yes-button-animation .2s cubic-bezier(.4,0,.2,1) forwards;
}

@media only screen and (min-width: 993px) {
  .QSIWebResponsive .QSIWebResponsive-creative-container-fade [class^='QSIWebResponsiveDialog'][class*='_content-spacious'] {
    min-width: 800px !important;
    width: 100% !important;
  }

  .QSIWebResponsive .QSIWebResponsive-creative-container-fade [class^='QSIWebResponsiveDialog'][class*='_headline'] {
    color: #2d2926 !important;
    font-family: 'Roboto' !important;
    font-size: 48px !important;
    font-style: normal !important;
    font-weight: 300 !important;
    letter-spacing: -0.02em !important;
    line-height: 125% !important;
  }

}
