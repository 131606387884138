/* stylelint-disable no-descending-specificity */
@layer utilities {
  .btn-disabled-priorauth-submit {
    background: theme('colors.gray.DEFAULT');
    color: theme('colors.gray.medium');
    cursor: not-allowed;
  }

  .btn-enabled-priorauth-submit {
    background: theme('colors.gold.DEFAULT');
    color: theme('colors.black');
    cursor: pointer;
    font-weight: bold;
  }

  .priorauth-input {
    border-color: theme('colors.gray.DEFAULT');
  }

  .priorauth-tooltip {
    max-width: 300px;
    z-index: 9999;
  }
}
