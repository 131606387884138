@layer components {
  .rte h1 {
    @apply heading-xl-extendable;
  }

  .rte h2 {
    @apply heading-lg-extendable;
  }

  .rte h3 {
    @apply heading-md-extendable;
  }

  .rte h4 {
    @apply heading-sm-extendable;
  }

  .rte h5 {
    @apply heading-xs-extendable;
  }

  .rte h6 {
    @apply heading-2xs-extendable;
  }

  .rte h1,
  .rte h2,
  .rte h3,
  .rte h4,
  .rte h5,
  .rte h6 {
    @apply mb-4;
  }

  /* If you're using invalid combinations, you don't get nice styling. */
  .rte h2 + h3,
  .rte h3 + h4,
  .rte h4 + h5,
  .rte h5 + h6 {
    @apply mt-6;
  }

  .rte h2:first-child,
  .rte h3:first-child,
  .rte h4:first-child,
  .rte h5:first-child,
  .rte h6:first-child {
    @apply mt-0;
  }

  .rte p,
  .rte a,
  .rte ul,
  .rte ol {
    @apply rte-body-extendable text-theme-text;
  }

  .rte a {
    @apply hover:underline font-semibold text-btn-link-text hover:text-btn-link-text-hover;
  }

  .rte p,
  .rte ul,
  .rte ol {
    @apply mb-6;
  }

  .rte ul {
    @apply list-disc pl-6 marker:text-xs;
  }

  .rte ul ul {
    @apply pt-4;

    list-style: circle;
  }

  .rte ol {
    @apply list-decimal pl-4;
  }

  .rte hr {
    @apply my-12;
  }

  .rte b,
  .rte strong {
    @apply font-bold text-theme-text;
  }

  .rte ul > li,
  .rte ol > li {
    @apply mb-3;
  }

  .rte ul > li:last-child,
  .rte ol > li:last-child {
    @apply mb-0;
  }

  .rte > *:last-child {
    @apply mb-0;
  }

  .rte .rte-align-left {
    @apply text-left;
  }

  .rte .rte-align-center {
    @apply text-center;
  }

  .rte .rte-align-right {
    @apply text-right;
  }

  .rte .rte-align-justify {
    @apply text-justify;
  }

  .rte .rte-indent-none {
    @apply pl-0;
  }

  .rte .rte-indent-1 {
    @apply pl-10;
  }

  .rte .rte-indent-2 {
    @apply pl-20;
  }

  .rte .rte-indent-3 {
    @apply pl-30;
  }

  .rte .rte-indent-4 {
    @apply pl-40;
  }

  .rte .rte-indent-5 {
    @apply pl-50;
  }

  .rte .rte-indent-6 {
    @apply pl-60;
  }

  .rte .rte-indent-7 {
    @apply pl-70;
  }

  .rte .rte-indent-8 {
    @apply pl-80;
  }

  /* Body Text  Large RTE Class */
  .rte .body-text-lg,
  .rte p.body-text-lg,
  .rte ul.body-text-lg,
  .rte ol.body-text-lg,
  .rte li.body-text-lg,
  .rte p span.body-text-lg,
  .rte li span.body-text-lg {
    @apply rte-body-text-lg;
  }

  /* Body Text  RTE Class */
  .rte .body-text,
  .rte p.body-text,
  .rte ul.body-text,
  .rte ol.body-text,
  .rte li.body-text,
  .rte p span.body-text,
  .rte li span.body-text {
    @apply rte-body-text-default;
  }

  /* Body Text Small RTE Class */
  .rte .body-text-sm,
  .rte p.body-text-sm,
  .rte ul.body-text-sm,
  .rte ol.body-text-sm,
  .rte li.body-text-sm,
  .rte p span.body-text-sm,
  .rte li span.body-text-sm {
    @apply rte-body-text-sm;
  }

  /* Body Text X-Small RTE Class */
  .rte .body-text-xs,
  .rte p.body-text-xs,
  .rte ul.body-text-xs,
  .rte ol.body-text-xs,
  .rte li.body-text-xs,
  .rte p span.body-text-xs,
  .rte li span.body-text-xs {
    @apply rte-body-text-xs;
  }

  /* Quote Text Class */
  .rte .quote-text,
  .rte p.quote-text,
  .rte li.quote-text,
  .rte p span.quote-text,
  .rte li span.quote-text {
    @apply rte-quote-text;
  }
}
