/* stylelint-disable no-descending-specificity */
@layer utilities {
  .header-navList-closeButton {
    background-color: theme('colors.gray.light');
    display: inline-flex;
    padding: 12px 20px !important;
    width: 100%;
  }

  .header-navList-closeButton:hover {
    background-color: theme('colors.gray.light') !important;
  }

  .header-navList-closeButton span {
    flex-grow: initial !important;
  }

  .header-navList-wingNav {
    background-color: theme('colors.white');
    height: 50vh;
    left: 100%;
    max-height: initial !important;
    overflow: hidden;
    position: absolute;
    top: 0;
  }

  .isOpen .isActive + .header-navList-wingNav {
    width: 100%;
  }

  .header-navList-wingNav .header-navList-menuItem {
    background-color: theme('colors.white') !important;
  }

  .header-navList-wingNav .header-navList-menuItem.current-item {
    color: theme('colors.blue.DEFAULT');
  }

  .header-navList {
    border-bottom: 1px solid theme('colors.gray.DEFAULT');
    padding: 12px 0 24px;
  }

  .header-search-navList {
    border-bottom: 1px solid theme('colors.gray.DEFAULT');
    display: flex;
    flex-flow: column;
    padding: 12px 0;
  }

  .header-navList .header-navList-menuItem ,
  .header-navList .ddl-lang-btn{
    align-items: center;
    background-color: theme('colors.white');
    color: theme('colors.gray.darkest');
    display: inline-flex;
    padding: 12px 0;
    width: 100%;
  }

  .header-navList .header-navList-menuItem span,
  .header-navList .ddl-lang-btn span {
    align-items: flex-end;
    color: theme('colors.blue.DEFAULT');
    flex-grow: 1;
    pointer-events: none;
  }

  .header-navList .ddl-lang-btn span svg,
  .header-navList .header-navList-menuItem span svg {
    transform: rotate3d(0, 0, 0, 0);
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .header-navList .ddl-lang-btn.isActive span svg,
  .header-navList .header-navList-menuItem.isActive span svg {
    transform: rotate3d(1, 0, 0, 180deg);
  }

  .header-navList li {
    font-size: theme('fontSize.lg');
    margin: 0;
  }

  .header-navList li li {
    font-size: theme('fontSize.base');
  }

  .header-navList li li:first-child {
    border-top: 1px solid theme('colors.gray.DEFAULT');
  }

  .header-navList li li .header-navList-menuItem,
  .header-navList li li .ddl-lang-btn {
    background-color: theme('colors.gray.light');
  }

  .header-navList li li .header-navList-menuItem:hover,
  .header-navList li li .ddl-lang-btn:hover {
    background-color: theme('colors.gray.light');
  }

  #header-flyout {
    background: theme('colors.white');
    display: flex;
    flex-direction: column;
    height: calc(100vh - 48px - 10px);
    overflow: scroll;
    position: absolute;
    top: calc(0px + 64px);
    transform: translateY(-100%);
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
    width: 100%;
    z-index: -1;
  }

  #header-flyout.isOpen {
    transform: translateY(0);
  }

  #header-flyout.isSlidOpen {
    transform: translateX(-100%);
  }

  #header-button-hamburger .line {
    background-color: theme('colors.blue.navy');
    border-radius: 2px;
    display: block;
    height: 2px;
    margin: 5px auto;
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
    width: 20px;
  }

  #header-button-hamburger:hover {
    cursor: pointer;
  }

  #header-button-hamburger.isActive .line:nth-child(2) {
    opacity: 0;
  }

  #header-button-hamburger.isActive .line:nth-child(1) {
    transform: translateY(7px) rotate(45deg);
  }

  #header-button-hamburger.isActive .line:nth-child(3) {
    transform: translateY(-7px) rotate(-45deg);
  }

  #header-button-search {
    margin-right: 26px;
    padding: 0;
  }

  #header-logo-mobile {
    flex-grow: 1;
  }

  #header-logo {
    flex-grow: 1;
    height: 36px;
    width: auto;
  }

  #header-menubar {
    align-items: center;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    height: 64px;
    width: 100%;
  }

  #navigation-footer {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    padding-bottom: 48px;
    width: 100%;
  }

  #navigation-footer > * {
    flex-grow: 1;
    justify-content: center;
    text-align: center;
  }

  #navigation-tertiary {
    border-bottom: none;
    display: flex;
  }

  #navigation-tertiary button {
    width: auto;
  }

  #navigation-tertiary li {
    font-size: theme('fontSize.sm');
  }

  #navigation-secondary li {
    font-size: theme('fontSize.base');
  }

  #navigation-secondary li li {
    font-size: theme('fontSize.sm');
  }

  @screen md {
    .header-navList {
      border-bottom: none;
      margin: 0;
      padding: 0;
    }

    .header-navList .header-navList-menuItem,
    .header-navList .ddl-lang-btn {
      background-color: transparent;
      font-size: theme('fontSize.base');
      font-weight: bold;
    }

    .header-navList li li {
      border-bottom: 1px solid theme('colors.gray.DEFAULT');
      width: 25%;
    }

    .header-navList li li:first-child {
      border-top: none;
    }

    .header-navList li li .header-navList-menuItem,
    .header-navList li li .ddl-lang-btn {
      background-color: transparent;
      font-size: theme('fontSize.md');
      font-weight: normal;
      height: 100%;
      padding: 16px 48px 16px 0;
      text-align: left;
    }
    .header-navList li li .ddl-lang-btn:hover,
    .header-navList li li .header-navList-menuItem:hover {
      background-color: transparent;
    }

    #header-button-search {
      display: none;
    }

    #header-flyout {
      display: flex;
      flex-direction: row;
      height: auto;
      overflow: initial;
      position: initial;
      top: initial;
      transform: none;
      width: 100%;
      z-index: initial;
    }

    #header-logo {
      flex-grow: initial;
      height: 40px;
      width: auto;
    }

    #navigation-footer {
      align-items: center;
      flex-grow: initial;
      padding-bottom: 0;
      width: auto;
    }

    #navigation-footer > * {
      flex-grow: initial;
    }

    #navigation-primary {
      flex-grow: 1;
    }

    #navigation-primary ul {
      gap: 48px;
    }

    
    @media only screen and (min-width: 960px) and (max-width: 1361px) {
      #navigation-primary ul {
        gap: 1.5vw !important;
      }
    }

    #navigation-primary li ul {
      align-items: flex-start;
      background-color: theme('colors.white');
      box-shadow: 0 1px 6px rgba(0 0 0 / 8%);
      display: flex;
      padding: 32px 130px 156px;
      width: 100%;
    }

    #navigation-primary ul > li > div > div {
      left: 0;
      position: absolute;
      top: 100%;
      width: 100%;
    }

    #navigation-primary .header-navList-menuItem {
      border-bottom: 3px solid transparent;
      padding: 0;
      transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    #navigation-primary .header-navList-menuItem.menu-open,
    #navigation-primary .header-navList-menuItem.current-item,
    #navigation-primary a.header-navList-menuItem:hover,
    #navigation-primary button.header-navList-menuItem:hover {
      border-bottom: 3px solid theme('colors.blue.DEFAULT');
    }

    #navigation-primary .header-navList-wingNav {
      border-top: 1px solid theme('colors.gray.DEFAULT');
      height: auto;
      left: auto;
      position: relative;
      top: auto;
      width: auto;
    }

    #navigation-primary .header-navList-wingNav li {
      border-bottom: none;
      width: auto;
    }

    #navigation-primary .header-navList-wingNav li .header-navList-menuItem {
      color: theme('colors.blue.navy');
      font-size: theme('fontSize.base');
      padding: 8px 0;
      pointer-events: initial;
    }

    #navigation-primary .header-navList-wingNav ul {
      display: block;
      gap: initial;
      padding: 0;
      width: auto;
    }

    #navigation-primary .header-navList-wingNav .header-navList-closeButton {
      display: none;
    }

    #navigation-primary.header-navList li li {
      border-bottom: none;
    }

    #navigation-secondary {
      height: 48px;
      left: 0;
      position: absolute;
      top: 0;
      z-index: 1;
    }

    #navigation-secondary ul {
      display: flex;
      flex-direction: row;
      height: 100%;
    }

    #navigation-secondary ul > li:last-child {
      margin: 0;
    }

    #navigation-secondary ul > li > div > div {
      left: -16px;
      position: absolute;
      top: calc(100% + 7px);
      width: 275px;
    }

    #navigation-secondary ul > li > div > div > ul,
    .login-menu-dropdown,
    #navigation-tertiary ul > li > div > div > ul {
      background: theme('colors.white');
      border: 1px solid theme('colors.gray.DEFAULT');
      border-radius: 8px;
      box-shadow: 0 2px 8px rgb(0 0 0 / 12%);
      color: #0b3b60;
      display: block;
      padding: 24px 0;
    }

    #navigation-secondary ul > li > div > div > ul:before,
    .login-menu-dropdown:before,
    #navigation-tertiary ul > li > div > div > ul:before {
      border: 10px solid transparent;
      border-bottom-color: theme('colors.white');
      bottom: 100%;
      content: '';
      display: block;
      height: 0;
      left: 24px;
      position: absolute;
      width: 0;
    }

    #navigation-secondary ul > li > div > div > ul:after,
    .login-menu-dropdown:after,
    #navigation-tertiary ul > li > div > div > ul:after {
      border: 9px solid transparent;
      border-bottom-color: white;
      bottom: 100%;
      display: none;
    }

    #navigation-secondary .header-navList-menuItem,
    #navigation-tertiary .ddl-lang-btn  {
      background-color: transparent;
      border-bottom: 1px solid transparent;
      color: theme('colors.white');
      font-size: theme('fontSize.sm');
      font-weight: 400;
      padding: 0;
      pointer-events: initial;
      transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    .ddl-lang-btn:before {
      border: 10px solid transparent;
      border-bottom-color: theme('colors.white');
      bottom: 100%;
      content: '';
      display: block;
      height: 0;
      left: 24px;
      position: absolute;
      width: 0;
    }

    #navigation-secondary .header-navList-menuItem.menu-open,
    #navigation-secondary .header-navList-menuItem:hover,
    #navigation-tertiary .ddl-lang-btn.menu-open,
    #navigation-tertiary .ddl-lang-btn:hover {
      background-color: transparent;
      border-bottom: 1px solid theme('colors.white');
    }

    #navigation-secondary.header-navList li li,
    #navigation-tertiary.header-navList li li.ddl-lang-li {
      border-bottom: none;
      width: 100%;
    }

    #navigation-secondary.header-navList li li:hover,
    #navigation-tertiary.header-navList li li.ddl-lang-li:hover {
      background-color: theme('colors.gray.DEFAULT');
    }

    /* TODO: ADD ONCE POPULATED BY LEGIT LINKS
  
  #navigation-secondary.header-navList li li:hover,
  #navigation-secondary .header-navList-menuItem.current-item {
    background-color: theme('colors.gray.DEFAULT');
  } */

    #navigation-tertiary.header-navList li li.ddl-lang-li:first-child,
    #navigation-tertiary.header-navList li li.ddl-lang-li:first-child {
      margin-top: 0;
    }

    #navigation-secondary.header-navList li li .header-navList-menuItem,
    #navigation-tertiary.header-navList li li .ddl-lang-btn {
      color: theme('colors.blue.navy');
      padding: 8px 24px;
    }

    #navigation-secondary .header-navList-menuItem span,
    #navigation-tertiary .ddl-lang-btn span {
      color: theme('colors.white');
    }

    #navigation-tertiary {
      height: 48px;
      position: absolute;
      right: 0;
      top: 0;
      width: auto;
    }

    #navigation-tertiary .header-navList-menuItem {
      color: theme('colors.white');
      font-size: theme('fontSize.sm');
      font-weight: theme('fontWeight.regular');
    }

    #navigation-tertiary .header-navList-menuItem span {
      color: theme('colors.teal');
    }

    #navigation-tertiary .header-navList-menuItem:hover {
      background-color: transparent;
    }
    #navigation-tertiary ul.ddl-lang-ul
    {
      width:auto;
      margin-top: 10px;
    }
  }
}
