/* Base */
@import 'tailwindcss/base';
@import './base/_base.font.css';
@import './base/_base.html.css';
@import './base/_base.button.css';
@import './base/_base.text.css';

/* Components */
@import '../components/authorable/site/Header/Header.styles.css';
@import '../components/authorable/layout/LayoutPageSection/LayoutPageSection.styles.css';
@import 'tailwindcss/components';
@import './_rte.css';
@import '../components/helpers/Coveo/search-results/AzBlueAtomicFacetSection.css';
@import '../components/helpers/Coveo/atomic-wrappers/AzBlueAtomicSearchBox.css';
@import '../components/authorable/general/IconBenefitsGrid/IconBenefitsGrid.css';
@import '../components/helpers/Coveo/atomic-wrappers/AzBlueAtomicResultsPerPage.css';
@import '../components/authorable/priorAuthLookup/PriorAuthProcedureCodeLookup/PriorAuthProcedureCodeLookup.style.css';
@import '../components/authorable/priorAuthLookup/PriorAuthLookup.style.css';

/* Utilities */
@import 'tailwindcss/utilities';
@import './utilities/_utilities.atomic.css';
@import './utilities/_utilities.editing.css';
@import './utilities/_utilities.headings.css';
@import './utilities/_utilities.qualtrics.css';
@import './utilities/_utilities.theme.css';
@import './utilities/_utilities.formstack.css';

/* Custom Styles */
@layer utilities {
  /* Prevent transitions from happening on page load */
  .pre-load * {
    transition: none !important;
  }

  .container-content-padding-x {
    @apply px-5 md:px-8 lg:px-12;
  }

  .container-content-width {
    @apply max-w-[1344px];
  }

  .hide-scroll-bar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .hide-scroll-bar::-webkit-scrollbar {
    display: none;
  }

  .stretched-link-hidden-text {
    @apply absolute opacity-0 inset-0 after:absolute after:inset-0;
  }

  .stretched-link {
    @apply inset-0 after:absolute after:inset-0;
  }

  .anchorIdIndentifier {
    @apply scroll-mt-28;
  }
}
