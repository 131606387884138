/* CSS For Search Results Page Filters Styling */

.search-left-filter atomic-facet::part(facet) {
  @apply border-0 border-t border-b border-gray rounded-none m-0 my-11 p-0 py-5;
}

.search-left-filter atomic-facet::part(label-button) {
  @apply hidden;
}

.search-left-filter atomic-facet::part(values) {
  @apply m-0;
}

.search-left-filter atomic-facet::part(value-checkbox) {
  @apply border border-gray w-6 h-6 rounded mr-3 transform-none animate-none;
}

.search-left-filter atomic-facet::part(ripple) {
  @apply hidden;
}

.search-left-filter atomic-facet::part(value-checkbox-checked) {
  @apply border border-gray w-6 h-6 rounded mr-3 transform-none animate-none hover:bg-blue;
}

.search-left-filter atomic-facet::part(value-checkbox-label) {
  @apply text-base leading-4 ml-3;
}

atomic-facet::part(value-count) {
  color: grey;
}

/* CSS For Article List Page Filters Styling */

.article-list atomic-popover {
  @apply p-0 mt-4 ml-4;
  @apply w-full min-w-[140px] max-w-[200px];
  @apply h-14;
}

.article-list atomic-popover::part(popover-button) {
  @apply flex flex-row items-center justify-between;
  @apply p-4 w-full h-full min-w-[140px] max-w-[200px];
  @apply border border-gray;
  @apply text-base leading-4 text-gray-dark;
  @apply relative;
}

.article-list atomic-popover::part(popover-button):hover,
.article-list atomic-popover::part(popover-button):focus {
  @apply border-2 border-blue rounded;
  @apply p-[15px];
  @apply text-gray-dark;
}

.article-list atomic-popover::part(arrow-icon) {
  @apply text-blue;
}

.article-list atomic-popover atomic-facet::part(facet) {
  @apply p-0;
  @apply border border-gray rounded;
  @apply w-full min-w-[140px] max-w-[200px] max-h-[240px] overflow-y-auto;
}

.article-list atomic-popover atomic-facet .contents {
  @apply bg-white;
  @apply w-full mx-auto min-w-[140px] max-w-[200px];

  margin-top: 2px;
}

.article-list atomic-popover atomic-facet::part(values) {
  @apply h-auto overflow-visible;
}

.article-list atomic-popover atomic-facet::part(value-link) {
  @apply px-4 py-2;
  @apply text-base leading-4 text-gray-dark;
}

.article-list atomic-popover atomic-facet::part(value-link):hover {
  @apply bg-gray-light text-gray-dark;
}

.article-list atomic-popover atomic-facet::part(value-link) span:hover {
  @apply text-gray-dark !important;
}
